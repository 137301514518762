import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'

import Navbar from '../components/navbar'
import '../styles/style.scss';



export default ({ data }) => (
    <div className="not-found">
        <section className="hero gradientBg is-fullheight-with-navbar">
            <Navbar />
            <div className="hero-body">
                <div className="container center">
                    <div className="is-fullwidth">
                        <strong className="is-size-4">404, Mate...</strong>
                        <p>Looks like I might'a lost yah page in the Outback.</p>
                        <p>Don't be sad, <Link to='/' className="text-span">head on home</Link> and we'll find it together.</p>
                    </div>
                </div>
            </div>
            <Img fluid={data.file.childImageSharp.fluid} />
        </section>
    </div>)


export const query = graphql`
    query{
        file(relativePath: { eq: "road-train.jpeg" }) {
            childImageSharp {
                fluid {
                    ...GatsbyImageSharpFluid_noBase64
                }
            }
            absolutePath
        }
    }
`
