
import React, { Children } from 'react'
import { Link } from 'gatsby'
import logo from '../images/AS.png'
import { FaSignature, FaTerminal, FaBook, FaDiceD20, FaFilePdf } from 'react-icons/fa';

const NavbarItem = props => (
	<Link className="navbar-item is-tab" to={props.page}>
		{props.children}
	</Link>
)

export default class Navbar extends React.Component {
	state = {
		activeMenu: false,
	}
	toggleMenu = () => {
		this.setState({
			activeMenu: !this.state.activeMenu,
		})
	}
	render() {
		return (
			<nav className="navbar has-shadow is-dark">
				<div className="container">
					<div className="navbar-brand">
						<Link className="navbar-item" to="/">
							<img src={logo} alt="logo" />
						</Link>
						<div
							onClick={this.toggleMenu}
							className={`navbar-burger burger ${this.state.activeMenu ? 'is-active' : ''}`}
						>
							<span />
							<span />
							<span />
						</div>
					</div>
					<div
						className={`navbar-menu ${this.state.activeMenu ? 'is-active' : ''}`}
					>
						<div className="navbar-end">
							<NavbarItem page="/">
								<span className="icon"><FaSignature /></span>
								<span>Home</span>
							</NavbarItem>
							<NavbarItem page="/code">
								<span className="icon"><FaTerminal /></span>
								<span>Code</span>
							</NavbarItem>
							<NavbarItem page="/writing">
								<span className="icon"><FaBook /></span>
								<span>Writing</span>
							</NavbarItem>
							<NavbarItem page="/games">
								<span className="icon"><FaDiceD20 /></span>
								<span>Games</span>
							</NavbarItem>
							<NavbarItem page="/publications">
								<span className="icon"><FaFilePdf /></span>
								<span>Publications</span>
							</NavbarItem>
						</div>
					</div>
				</div>
			</nav>
		)
	}
}